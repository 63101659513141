import { useAuth } from "providers/AuthContext";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Navigate,
} from "react-router-dom";
import { LandingPage } from "pages/Landing";

import { ViewEvent } from "pages/Events/ViewEvent";
import { Account } from "pages/Account";
import { Checkout } from "pages/Checkout";
import { CheckoutConfirmation } from "pages/Checkout/CheckoutConfirmation";
import { Selling } from "pages/Marketing/Selling";
import { ViewOrder } from "pages/Orders/ViewOrder";
import { EventSearch } from "pages/Events";
import { ViewVenue } from "pages/Venues/ViewVenue";
import { GetStarted } from "pages/Marketing/GetStarted";
import { AnimatedRoutes } from "providers/AnimatedRoutes";
import { Landing3 } from "pages/Landing/Landing3";
import { ViewOrganization } from "pages/Organizations/ViewOrganization";
import { Elements } from "@stripe/react-stripe-js";
import { Appearance, loadStripe } from "@stripe/stripe-js";
import { PrivacyPolicy } from "pages/PrivacyPolicy";
import { ContactPage } from "pages/Marketing/ContactPage";
import { NewTicketTransfer } from "pages/NewTicketTransfer";
import { Pricing } from "pages/Marketing/Pricing";
import { ForgotPassword, ResetPassword } from "pages/Auth/ResetPassword";
import { HappySupportGuy } from "components/HappySupportGuy";
import { TermsOfService } from "pages/TermsOfService";
import { Login } from "pages/Auth/Login";
import { Registration } from "pages/Auth/Registration";
import { Auth } from "pages/Auth";
import Footer from "components/navigation/Footer";
const appearance: Appearance = {
  theme: 'stripe'
}
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY || "nope");
function App() {
  const { user } = useAuth();
  return (
    <>
      <Router>
        {user ? (
          <AnimatedRoutes>
            <Route path="/" element={<Landing3 />} />
            <Route path="/account/*" element={
              <Elements options={{appearance}} stripe={stripePromise}>
                <Account />
              </Elements>
            } />
            <Route path="/explore" element={<EventSearch />} />
            <Route path="/events/:id" element={<ViewEvent />} />
            <Route path="/venues/:id" element={<ViewVenue />} />
            <Route path="/orgs/:id" element={<ViewOrganization />} />
            <Route path="/checkout" element={
              <Elements options={{appearance}} stripe={stripePromise}>
                <Checkout />
              </Elements>
            } />
            <Route path="/orders/:uid" element={<ViewOrder />} />
            <Route path="/confirmation" element={<CheckoutConfirmation />} />
            <Route path="/sell-on-happy" element={<Selling />} />
            <Route path="/get-started/*" element={<GetStarted />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="*"
              element={<Navigate to="/account" replace />}
            />
          </AnimatedRoutes>
        ) : (
          <AnimatedRoutes>
            <Route path="/" element={<Landing3 />} />
            <Route path="/verify-transfer" element={<NewTicketTransfer />} />
            <Route path="/sell-on-happy" element={<Selling />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/get-started/*" element={<GetStarted />} />
            <Route path="/explore" element={<EventSearch />} />
            <Route path="/events/:id" element={<ViewEvent />} />
            <Route path="/venues/:id" element={<ViewVenue />} />
            <Route path="/orgs/:id" element={<ViewOrganization />} />
            <Route path="/login" element={<Auth />} />
            <Route path="/register" element={<Registration />} />
            <Route path="/orders/:uid" element={<ViewOrder />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/checkout" element={null} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </AnimatedRoutes>
        )}
        <Footer />
      </Router>
    </>
  );
}

export default App;
