import { Fragment, useEffect, useMemo, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  TicketIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "providers/AuthContext";
import { Button } from "components/buttons";
import { ShoppingCartIcon } from "@heroicons/react/24/outline";
import { sumBy } from "lodash";
import { ArrowRightEndOnRectangleIcon, Cog6ToothIcon, MagnifyingGlassIcon, TrashIcon } from "@heroicons/react/24/solid";
import { format } from "date-fns";
import { makeApiRequest, RequestProps } from "utils/api";
import { formatCurrency } from "utils/currency";
import classNames from "classnames";
import { Modal } from "components/layout";
import { LoginForm, RegisterForm } from "components/forms/Auth";
import { GLogin } from "components/forms/GLogin";
import { useNotification } from "providers/NotificationContext";
import { OtpForm } from "pages/Auth/OtpForm";

export const Navbar = ({ sticky = false }: { sticky?: boolean }) => {
  const [showCart, setShowCart] = useState<boolean>(false);
  const hideCart = () => {
    setShowCart(false);
  };
  const styles = sticky
    ? `bg-white shadow w-full fixed top-0 z-50`
    : `bg-white shadow w-full relative z-50`;
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout, setShowAuthModal } = useAuth();

  return (
    <>
      <Disclosure as="nav" className={styles}>
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <button
                    onClick={() => navigate("/")}
                    className="flex flex-shrink-0 items-center"
                  >
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                      alt="Happy Ticketing Company"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
                      alt="Happy Ticketing Company"
                    />
                    <span className="text-purple pl-2 font-milkshake">
                      Happy
                    </span>
                  </button>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-purple-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      to="/explore"
                      className={classNames(
                        "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900",
                        location.pathname.includes("/explore") && "border-b-2"
                      )}
                    >
                      Explore
                    </Link>

                    <Link
                      to="/sell-on-happy"
                      className={classNames(
                        "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900",
                        location.pathname.includes("/sell-on-happy") &&
                          "border-b-2"
                      )}
                    >
                      Sell Tickets
                    </Link>
                    <Link
                      to="/pricing"
                      className={classNames(
                        "inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900",
                        location.pathname.includes("/pricing") &&
                          "border-b-2 border-purple"
                      )}
                    >
                      Pricing
                    </Link>
                    {/* <Link
                      to="#"
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      Sports
                    </Link> */}
                    {/* <a
                      href="#"
                      className="inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                    >
                      Calendar
                    </a> */}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                  {/* Profile dropdown */}
                  {user ? (
                    <>
                      <button
                        type="button"
                        className="hidden rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex items-center gap-2 rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                            <span className="sr-only">Open user menu</span>
                            <UserCircleIcon className="h-8 w-8 text-purple" />
                            <span className="text-purple font-bold">
                              {user.first_name}
                            </span>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/account/tickets"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Your Tickets
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/account/settings"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Account Settings
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={logout}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                  )}
                                >
                                  Log Out
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => setShowAuthModal(true)}
                        variant="link"
                      >
                        Log In
                      </Button>
                      <Link className="ml-6" to="/register">
                        <Button className="rounded-full border-2 border-purple text-purple">
                          Create Account
                        </Button>
                      </Link>
                    </>
                  )}
                </div>
                <div className="-mr-2 flex items-center sm:hidden">
                  <button className="flex" onClick={() => setShowCart(true)}>
                    <span className="text-pink font-extrabold animate-pulse">
                      {user?.cart.items.length || ""}
                    </span>
                    <ShoppingCartIcon className="w-6 h-6 text-purple" />
                  </button>
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-purple hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="absolute z-10 w-full sm:hidden bg-gray-300">
              {/* <div className="space-y-1 pt-2 pb-3">
                <Disclosure.Button
                  as="button"
                  onClick={() => navigate("/explore")}
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500"
                >
                  Find Events
                </Disclosure.Button>
              </div> */}
              {user ? (
                <div className="border-t border-gray-400 pt-4 pb-3">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <UserCircleIcon className="h-10 text-gray-400" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-500">
                        {user.first_name} {user.last_name}
                      </div>
                      <div className="text-sm font-medium text-purple">
                        {user.email}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="hidden ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as="button"
                      onClick={() => navigate("/account")}
                      className="block px-4 py-2 text-base font-medium text-gray-500"
                    >
                      Account
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={() => navigate("/account/orders")}
                      className="block px-4 py-2 text-base font-medium text-gray-500"
                    >
                      Order History
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="button"
                      onClick={logout}
                      className="block px-4 py-2 text-base font-medium text-gray-500"
                    >
                      Sign Out
                    </Disclosure.Button>
                  </div>
                </div>
              ) : (
                <div className="border-t border-gray-200 pt-4 pb-3">
                  <div className="mt-3 space-y-1">
                    <Disclosure.Button
                      as="button"
                      onClick={() => navigate("/login")}
                      className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800"
                    >
                      Sign In
                    </Disclosure.Button>
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {showCart ? <ShoppingCart hideCart={hideCart} /> : null}
      <LoginModal />
      <MobileNav />
    </>
  );
};

export const LoginModal = ({}: {}) => {
  const { showError } = useNotification();
  const [showOtpForm, setShowOtpForm] = useState<boolean>(true);
  const { showAuthModal, setShowAuthModal, register, login, user } = useAuth();
  const [showForm, setShowForm] = useState<"login" | "register">("login");
  const handleModalLogin = (values: { email: string; password: string }) => {
    login(values);
  };

  const handleModalRegister = (values: {
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    phone_number: string;
  }) => {
    register(values).then((res) => {
      if (res.status === 201) {
        setShowAuthModal(false);
      }
      if (res.status === 406) {
        showError("An account with that email already exists. Please log in.");
      }
    });
  };

  // hide modal once a user is logged in
  useEffect(() => {
    if (user) {
      setShowAuthModal(false);
    }
  }, [user]);

  return (
    <Modal
      onRequestClose={() => setShowAuthModal(false)}
      isOpen={showAuthModal}
    >
      <div className="flex justify-center items-center">
        <img
          className="w-12 py-4"
          src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-colored.svg"
          alt="Happy Ticketing Company"
        />
        <div className="ml-2">
          <h2 className="text-3xl font-milkshake text-purple">
            Happy Ticketing
          </h2>
        </div>
      </div>
      {showForm === "login" && (
        <>
          {showOtpForm ? (
            <OtpForm showOtp={setShowOtpForm} />
          ) : (
            <LoginForm
              handleSubmit={handleModalLogin}
              handleLinkClick={() => setShowForm("register")}
              setShowOtpForm={setShowOtpForm}
            />
          )}
        </>
      )}
      {showForm === "register" && (
        <>
          <h3 className="text-xl text-purple font-extrabold">
            Create your account
          </h3>
          <RegisterForm
            handleSubmit={handleModalRegister}
            handleLinkClick={() => setShowForm("login")}
          />
        </>
      )}
      <div className="hidden lg:block">
        <span className="block text-center mb-5">Or</span>
        <GLogin tos={true} />
      </div>
    </Modal>
  );
};

export const ShoppingCart = ({ hideCart }: { hideCart: () => void }) => {
  const { user, setCurrentUser } = useAuth();
  console.log(user);
  const navigate = useNavigate();
  const items = user?.cart.items;
  const deleteCartItem = (item: any) => {
    const requestProps: RequestProps = {
      path: `/cart_items/${item.id}`,
      method: "DELETE",
    };

    makeApiRequest(requestProps).then((res) => {
      const user = res.data;
      setCurrentUser(user);
    });
  };
  return (
    <div
      style={{ zIndex: 101 }}
      className="transition-all fixed w-screen h-screen bg-white top-0 flex flex-col"
    >
      <button onClick={hideCart} className="absolute top-5 right-5 m-auto">
        <XMarkIcon className="h-8 w-8 text-purple" />
      </button>
      <h3 className="text-purple text-xl font-extrabold p-5 border-b border-gray-300 mb-4">
        My Cart ({items?.length} items)
      </h3>

      {!user?.cart.event || !items?.length ? (
        <div className="h-full w-full flex flex-col gap-4 justify-center items-center">
          <span>You have no items in your cart.</span>
          <Link to="/explore">
            <Button variant="link">Let's change that.</Button>
          </Link>
        </div>
      ) : (
        <>
          <div className="border-b border-gray-300 p-5">
            <div className="flex">
              <img
                src={user?.cart.event_image}
                className="w-10 border border-aqua"
              />
              <Link
                className="text-lg pl-3 text-pink font-bold leading-6"
                to={`/events/${user?.cart.event?.slug}`}
              >
                {user?.cart.event?.name} -{" "}
                {format(
                  new Date(user?.cart.event?.start_date || ""),
                  "M/dd/yy"
                )}
              </Link>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex-grow">
                {user?.cart.items?.map((item) => {
                  const ticketInfo = item.ticket_info;
                  const priceLevelInfo = item.price_level_info;
                  return (
                    <div className="flex justify-between items-center mt-4">
                      <div>
                        <span className="flex items-center">
                          <TicketIcon className="w-5 inline mr-2" />
                          {priceLevelInfo.name} x 1&nbsp;
                          <Button
                            onClick={() => deleteCartItem(item)}
                            variant="link"
                          >
                            <TrashIcon className="w-4" />
                          </Button>
                        </span>
                        <span className="block text-sm text-gray-400">
                          {ticketInfo.tier} - {ticketInfo.section}
                        </span>
                      </div>
                      <span className="font-extrabold text-lg">
                        {formatCurrency(item.price_cents)}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="p-5 flex justify-between text-lg">
            <span className="font-extrabold">Subtotal:</span>
            <span className="font-extrabold">
              {formatCurrency(sumBy(items, "price_cents"))}
            </span>
          </div>
          <div className="p-5">
            <Button
              onClick={() => navigate("/checkout")}
              variant="primary"
              className="w-full h-14"
            >
              Go To Checkout
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export const MobileNav = ({}: {}) => {
  const { user } = useAuth();
  const location = useLocation();
  const accountPaths = ["/account/orders", "/account/settings"];
  const ticketPaths = ["/account/tickets", "/account/ticket-transfers"];
  const isExplorePath =
    location.pathname.includes("/explore") ||
    location.pathname.includes("/events") ||
    location.pathname.includes("/checkout");

  return (
    <div className="fixed z-10 sm:hidden bottom-0 w-full min-h-16 flex justify-between bg-gradient-to-r from-purple to-lightPurple pt-4 pb-8">
      <div
        className={classNames("flex justify-center items-center h-full", 
          user ? "w-1/3" : "w-1/2"
        )}
      >
        <Link
          className={classNames(
            "flex flex-col text-center items-center font-bold",
            isExplorePath ? "text-white" : "text-white/50"
          )}
          to="/explore"
        >
          <MagnifyingGlassIcon className="w-6 h-6" />
          <span className="text-sm">Explore</span>
        </Link>
      </div>
      {user ? (
        <>
          <div
            className={"w-1/3 flex justify-center items-center h-full"}
          >
            <Link
              className={classNames(
                "flex flex-col text-center items-center font-bold",
                  ticketPaths.includes(location.pathname) ? "text-white" : "text-white/50"
              )}
              to="/account/tickets"
            >
              <TicketIcon className="w-6 h-6" />
              <span className="text-sm">My Tickets</span>
            </Link>
          </div>
          <div className="w-1/3 flex justify-center items-center h-full">
            <Link
              className={classNames(
                "flex flex-col text-center items-center font-bold",
                  accountPaths.includes(location.pathname) ? "text-white" : "text-white/50"
              )}
              to="/account/settings"
            >
              <Cog6ToothIcon className="w-6 h-6" />
              <span className="text-sm">Account</span>
            </Link>
          </div>
        </>
      ) : (
        <div className="w-1/2 flex justify-center items-center h-full">
          <Link
            className={classNames(
              "flex flex-col text-center items-center font-bold",
                location.pathname === '/login' ? "text-white" : "text-white/50"
            )}
            to="/login"
          >
            <ArrowRightEndOnRectangleIcon className="w-6 h-6" />
            <span className="text-sm">Log In</span>
          </Link>
        </div>
      )}
    </div>
  );
};
