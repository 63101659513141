import axios, {AxiosRequestConfig} from "axios";
import { makeAuthHeader } from "./auth";

export type ApiError = {
  message: string
}
const _defaultHeaders = {
  "Content-Type": "application/json",
  Accept: 'application/json'
};

export interface RequestProps {
  external?: boolean
  method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE";
  path: string;
  params?: any;
  responseType?: "json" | "text" | "blob" | "arraybuffer";
}

const baseUrl = import.meta.env.VITE_API_URL;

export const makeApiRequest = async ({
  external = false,
  method = "GET",
  params,
  path,
  responseType = "json",
}: RequestProps) => {
  
  const defaultHeaders = Object.assign({}, _defaultHeaders, makeAuthHeader());
  const axiosParams = Object.assign({}, params);

  const options: AxiosRequestConfig = {
    method,
    url: `${!external ? baseUrl : ''}${path}`,
    headers: defaultHeaders,
    timeout: 30000,
    responseType,
  };
  if (method.toUpperCase() === "GET") {
    options.params = axiosParams;
  } else {
    options.data = axiosParams;
  }

  try {
    const response = await axios(options);
    return Promise.resolve(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // This is an Axios error. You can access the error response like this:
      const errorResponse = error.response;
      // You can then handle the error response as needed, or re-throw the error.
      return Promise.reject(errorResponse);
    } else {
      // This is an unexpected error, re-throw it.
      throw error
    }
  }
};
