import { Container } from "components/layout";
import { Navbar } from "components/navigation/Navbar";
import {AccountNavigationDropdown} from "./NavigationMenu";
import { Navigate, Route, Routes } from "react-router-dom";
import { Settings } from "./Settings";
import { OrderHistory } from "./OrderHistory";
import { MyTickets } from "./MyTickets";
import Footer from "components/navigation/Footer";
import { TicketTransfers } from "./TicketTransfers";
import { ViewOrder } from "pages/Orders/ViewOrder";

export const Account = ({}: {}) => {
  return (
    <>
      <Navbar />
      <Container className="pt-6 sm:pt-10">
        <div className="flex justify-between">
          <h2 className="text-purple font-extrabold text-2xl">Account</h2>
          <AccountNavigationDropdown />
        </div>
      </Container>
      <div className="w-full sm:max-w-xl sm:mx-auto p-5 min-h-screen">
        <Routes>
          {/* <Route path="/" element={<MyTickets />} /> */}
          <Route path="orders" element={<OrderHistory />} />
          <Route path="orders/:orderId" element={<ViewOrder />} />
          <Route path="tickets" element={<MyTickets />} />
          <Route path="ticket-transfers" element={<TicketTransfers />} />
          <Route path="settings" element={<Settings />} />
          <Route path="*" element={<Navigate to="settings" replace />} />
        </Routes>
      </div>
    </>
  );
};
