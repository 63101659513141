// # https://lvgweb.s3.us-east-2.amazonaws.com/happy/landing-3-bg.jpg
const videoUrl =
  "https://lvgweb.s3.us-east-2.amazonaws.com/happy/happy_ticketing_overview.mp4";
import { LandingNav } from "./Nav";
import { Link } from "react-router-dom";
import Footer from "components/navigation/Footer";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { MarketingVideo } from "components/MarketingVideo";
import { useRef, useState } from "react";
import { Modal } from "components/layout";
import { set } from "lodash";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { PlayCircleIcon } from "@heroicons/react/24/outline";
import { FadeText } from "./FadeText";
export const Landing3 = () => {
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const width = window.innerWidth;
  const height = window.innerHeight;
  const isMobile = width < 640;
  const playVideo = () => {
    setShowVideoModal(true);
  };

  return (
    <>
      <Helmet>
        <title>Happy Ticketing | Buy and Sell Tickets for Less</title>
        <meta
          name="description"
          content="Ticketing platform that is flexible and free to use for ticket vendors, less cost for ticket purchasers."
        />
        <meta name="keywords" content="tickets, events, concerts, sports" />
      </Helmet>
      <LandingNav />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="relative flex flex-col sm:flex-row justify-center items-center"
      >
        <div
          style={{ height }}
          className="w-screen flex flex-col items-center justify-center gap-5 px-4"
        >
          <div
            style={{
              background: `url(//lvgweb.s3.us-east-2.amazonaws.com/happy/landing-3-bg-final.jpg)no-repeat center center/cover`,
            }}
            className="fixed top-0 -z-10 w-screen h-screen opacity-30"
          />
          <div className="flex justify-center items-center">
            <div className="w-full flex flex-col items-center">
              <span className="text-purple font-milkshake text-2xl md:text-4xl">
                Happy Ticketing
              </span>
              <h1 className="bg-gradient-to-r from-pink via-malachite to-lightPurple inline-block text-transparent bg-clip-text text-4xl sm:max-w-2xl py-5 text-center">
                A Ticketing Platform for your{" "}
                <span className="hidden sm:block">

                </span>
                <span className="italic">
                  <FadeText />
                </span>{" "}
              </h1>
              <div className="w-full flex flex-col sm:flex-row justify-center gap-8 mt-5 sm:mt-10 items-center">
                <Link
                  data-testid="sell-tickets"
                  to="/sell-on-happy"
                  className="shadow-lg bg-purple px-16 py-4 text-white text-xl font-bold rounded-lg hover:brightness-75 active:translate-y-1"
                >
                  Sell Tickets
                </Link>

                <button
                  onClick={playVideo}
                  className="flex items-center gap-2 shadow-lg bg-transparent border-4 border-pink hover:bg-pink/30 px-12 py-3 text-pink text-base font-bold rounded-lg active:translate-y-1"
                >
                  <PlayCircleIcon className="h-8 w-8" />
                  Watch Video
                </button>
              </div>
            </div>
          </div>

          {/* <p className="text-gray-500 sm:text-2xl sm:max-w-2xl font-thin text-center">
            A ticketing platform that's free for vendors and less fees for
            buyers.
          </p> */}
        </div>
      </motion.div>
      <Modal
        size={"screen"}
        onRequestClose={() => setShowVideoModal(false)}
        isOpen={showVideoModal}
      >
        <div className="flex justify-end">
          <span
            className="ml-auto"
            role="button"
            onClick={() => setShowVideoModal(false)}
          >
            <XMarkIcon className="h-10 w-10 text-gray-500" />
          </span>
        </div>
        <div className="flex items-center justify-center">
          <MarketingVideo
            autoPlay={true}
            width={isMobile ? 300 : width - 400}
          />
        </div>
      </Modal>
    </>
  );
};
