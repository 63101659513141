import { CheckIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/outline";
import Footer from "components/navigation/Footer";
import { Navbar } from "components/navigation/Navbar";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";

export const Pricing = () => {
  const [ticketPrice, setTicketPrice] = useState<number>(25);
  const priceComparisonRef = useRef<HTMLDivElement>(null);

  const scrollToPriceComparison = () => {
    priceComparisonRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <Navbar />
      <div className="isolate overflow-hidden bg-purple">
        <div className="mx-auto max-w-7xl px-6 pb-96 pt-24 text-center sm:pt-32 lg:px-8">
          <div className="mx-auto max-w-4xl">
            <h2 className="text-base font-semibold leading-7 text-white flex gap-2 items-center justify-center">
              <img
                src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                className="w-10"
              />
              <span className="text-xl font-milkshake">Pricing</span>
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              A lower price, <br className="hidden sm:inline lg:hidden" />
              whoever you are
            </p>
          </div>
          <div className="relative mt-6">
            <p className="mx-auto max-w-2xl text-lg leading-8 text-white/70">
              Selling tickets? You have NO cost to operate with Happy. Buying
              tickets? You pay a fee up to 20% lower than the competition.{" "}
              {/* <span
                className="text-yellow hover:brightness-125"
                onClick={scrollToPriceComparison}
                role="button"
              >
                Check it out for yourself.
              </span> */}
            </p>
            <svg
              viewBox="0 0 1208 1024"
              className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
            >
              <ellipse
                cx={604}
                cy={512}
                fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb1)"
                rx={604}
                ry={512}
              />
              <defs>
                <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb1">
                  <stop stopColor="#FFBA08" />
                  <stop offset={1} stopColor="#FFBA08" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className="flow-root bg-white pb-24 sm:pb-32">
          <div className="-mt-80">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
                <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
                  <div>
                    <h3 className="text-base font-semibold leading-7 text-indigo-600">
                      Ticket Buyers
                    </h3>
             
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        $1.50
                      </span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        per ticket
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      We get it, fees suck. At Happy we do our best to keep fees
                      well below our competitors.{" "}
                      {/* <span
                        onClick={scrollToPriceComparison}
                        role="button"
                        className="text-pink"
                      >
                        see for yourself.
                      </span> */}
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      <li className="flex gap-x-3">
                        <PlusIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        2.99% + $0.30 card processing fee
                      </li>

                      <li className="flex gap-x-3">
                        <PlusIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        Applicable taxes
                      </li>

                      <li className="flex gap-x-3">
                        <PlusIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        NO Fees on $0 tickets 
                      </li>
                    </ul>
                  </div>
                  <a
                    href={""}
                    aria-describedby={""}
                    className="mt-8 block rounded-md bg-purple px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:brightness-125 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple"
                  >
                    Find Tickets
                  </a>
                </div>

                <div className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10">
                  <div>
                    <h3 className="text-base font-semibold leading-7 text-indigo-600">
                      Ticket Sellers
                    </h3>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <span className="text-5xl font-bold tracking-tight text-gray-900">
                        FREE
                      </span>
                      <span className="text-base font-semibold leading-7 text-gray-600">
                        to sell tickets
                      </span>
                    </div>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                      Seriously. No fees, no hidden costs.
                      Just start selling tickets and keep 100% of your earnings.
                    </p>
                    <ul
                      role="list"
                      className="mt-10 space-y-4 text-sm leading-6 text-gray-600"
                    >
                      <li className="flex gap-x-3">
                        <PlusIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        Sell concessions at your events with no Happy fees, just a processing fee of 2.7% + $0.15
                      </li>

                      <li className="flex gap-x-3">
                        <PlusIcon
                          className="h-6 w-5 flex-none text-indigo-600"
                          aria-hidden="true"
                        />
                        FREE access to Happy Ticketing Vendor App for box office sales and entry
                        scanning
                      </li>
                    </ul>
                  </div>
                  <a
                    href={""}
                    aria-describedby={""}
                    className="mt-8 block rounded-md bg-purple px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:brightness-125 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple"
                  >
                    Sell Tickets
                  </a>
                </div>
                <div className="flex flex-col items-start gap-x-8 gap-y-6 rounded-3xl p-8 ring-1 ring-gray-900/10 sm:gap-y-10 sm:p-10 lg:col-span-2 lg:flex-row lg:items-center">
                  <div className="lg:min-w-0 lg:flex-1">
                    <h3 className="text-lg font-semibold leading-8 tracking-tight text-indigo-600">
                      Pilot Program
                    </h3>
                    <p className="mt-1 text-base leading-7 text-gray-600">
                      Ask about our pilot program for early adopters. Get a free welcome package and lock in other perks. Get started selling with Happy Ticketing today! <Link className="text-pink" to="/contact">Contact us now.</Link>
                    </p>
                  </div>
                  {/* <a
                    href="#"
                    className="rounded-md px-3.5 py-2 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Buy discounted license{" "}
                    <span aria-hidden="true">&rarr;</span>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <section
          ref={priceComparisonRef}
          id="price-comparison"
          className="relative w-full flex flex-col gap-3 items-center text-white py-6 sm:py-10"
        >
          <span className="block text-center w-full text-4xl font-bold">
            Go Ahead, Try Us.
          </span>
          <p>
            Use the slider below to adjust the ticket price and compare fees.
          </p>
          <span className="text-lg font-thin">
            Ticket Price: <b>${ticketPrice}</b>
          </span>
          <div className="mx-auto max-w-md flex flex-col">
            <div className="w-full">
              <input
                value={ticketPrice}
                onChange={(e) => {
                  setTicketPrice(parseInt(e.target.value));
                }}
                type="range"
                min="10"
                max="500"
                step="5"
                className="w-full h-2 bg-pink rounded-md appearance-none"
              />
            </div>
          </div>

          <div className="flex justify-between gap-4 items-center w-full sm:max-w-lg mt-10">
            <span className="p-3 h-24 justify-center rounded-lg bg-white/30 flex flex-col text-sm text-center w-1/3">
              <span className="font-bold">Tmaster Tickets</span>
              <span className="text-red-500">
                ~ ${(ticketPrice * 0.25).toFixed(2)}
              </span>
            </span>
            <span className="p-3 h-24 justify-center rounded-lg bg-white/30 flex flex-col text-center w-1/3">
              <span className="font-milkshake text-xl text-purple">Happy</span>
              <span className="text-lightPurple">
                ~ ${(ticketPrice * 0.039 + 1.5).toFixed(2)}
              </span>
            </span>
            <span className="p-3 h-24 justify-center rounded-lg bg-white/30 flex flex-col text-sm text-center w-1/3">
              <span className="font-bold">Brite Tickets</span>
              <span className="text-red-500">
                ~ ${((ticketPrice * 0.037) + 1.79).toFixed(2)}
              </span>
            </span>
          </div>

          <span className="block text-center mt-10 text-lg">
            Have questions? <Link className="text-yellow" to="/contact">Reach out.</Link> We'd love to
            hear from you.
          </span>
          <svg
            viewBox="0 0 1208 1024"
            className="absolute -top-10 left-1/2 -z-10 h-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:-top-12 md:-top-20 lg:-top-12 xl:top-0"
          >
            <ellipse
              cx={604}
              cy={512}
              fill="url(#6d1bd035-0dd1-437e-93fa-59d316231eb0)"
              rx={604}
              ry={512}
            />
            <defs>
              <radialGradient id="6d1bd035-0dd1-437e-93fa-59d316231eb0">
                <stop stopColor="#4cc9f0" />
                <stop offset={1} stopColor="#4cc9f0" />
              </radialGradient>
            </defs>
          </svg>
        </section> */}
      </div>
    </>
  );
};
