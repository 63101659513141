import { useAuth } from "providers/AuthContext";
import QRCode from "react-qr-code";
import { RequestProps } from "utils/api";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "utils/api";
import { format, isAfter } from "date-fns";
import { Event } from "types/Event";
import TicketCarousel from "components/carousel/TicketCarousel";
import { Ticket } from "types/Ticket";
import { groupBy, keys, orderBy, uniqBy } from "lodash";
import { useState } from "react";

import { Button } from "components/buttons";
import { ChevronDoubleLeftIcon } from "@heroicons/react/24/solid";
import {
  PaperAirplaneIcon,
  TicketIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Modal } from "components/layout";
import { InputOption, SelectInput, TextInput } from "components/forms";
import { User } from "types/User";
import { useNotification } from "providers/NotificationContext";

const AddToWalletButton = ({ ticketId }: { ticketId: number }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAddToWallet = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await makeApiRequest({
        path: `/tickets/${ticketId}/download_pass`,
        method: 'GET',
        responseType: 'blob',
      });

      if (response.status === 200) {
        const blob = response.data;

        const url = window.URL.createObjectURL(
          new Blob([blob], { type: 'application/vnd.apple.pkpass' })
        );

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `ticket_${ticketId}.pkpass`);

        document.body.appendChild(link);
        link.click();

        link?.parentNode?.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (err) {
      console.error('Error downloading pass:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={handleAddToWallet}
        className="add-to-wallet-button"
        disabled={loading}
      >
        {loading ? (
          <img
            src="/apple-wallet.svg"
            alt="Add to Apple Wallet"
            className="w-full max-w-xs h-12 mt-2 opacity-50"
          />
        ) : (
          <img
            src="/apple-wallet.svg"
            alt="Add to Apple Wallet"
            className="w-full max-w-xs mt-2 h-12"
          />
        )}
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

const filterOptions: InputOption[] = [
  { display: "Upcoming Tickets", value: "upcoming" },
  { display: "All Tickets", value: "all" },
  { display: "Past Tickets", value: "past" },
];

export const MyTickets = ({}: {}) => {
  const { user } = useAuth();
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [scope, setScope] = useState<InputOption>(filterOptions[0]);
  const [showTransferModal, setShowTransferModal] = useState<number | null>(
    null
  );
  const [showEventDescription, setShowEventDescription] =
    useState<boolean>(false);

  const requestProps: RequestProps = {
    path: "/tickets",
    params: {
      user_id: user?.id,
      scope: scope?.value,
    },
  };
  const { data } = useQuery({
    queryKey: ["tickets", user, scope],
    queryFn: () => makeApiRequest(requestProps),
    enabled: !!user,
    refetchOnWindowFocus: false,
  });

  const tickets = data?.data;
  const orderedTickets = orderBy(
    tickets,
    (ticket: Ticket) => new Date(ticket.event.start_date)
  );

  const groupedTickets = groupBy(orderedTickets, (t) => t.event.name);
  const events = uniqBy(
    orderedTickets.map((t) => t.event),
    "id"
  );

  const ticketsForEvent = tickets?.filter(
    (t: Ticket) => t.event.id === selectedEvent?.id
  );

  
  

  return (
    <>
      <div className="pb-40">
        {selectedEvent ? (
          <>
            <button
              onClick={() => setSelectedEvent(null)}
              className="font-bold mt-6 text-gray-600 flex items-center"
            >
              <ChevronDoubleLeftIcon className="h-4" /> Back to events
            </button>
            <TicketCarousel>
              {ticketsForEvent?.map((ticket: Ticket) => {
                return (
                  <div
                    style={{
                      borderColor: ticket.color,
                    }}
                    className={`text-center border-2 pb-2 rounded-tr-lg rounded-tl-lg`}
                  >
                    <div
                      style={{
                        backgroundColor: ticket.color,
                      }}
                      className={`w-full py-4 text-white flex gap-2 items-center justify-center rounded-tr-lg rounded-tl-lg -mt-1`}
                    >
                      <img
                        src="//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-white.svg"
                        alt="happy white"
                        className="w-8"
                      />
                      <span className="font-milkshake text-xl">
                        Happy Ticketing
                      </span>
                    </div>
                    <span className="my-3 text-lg block font-bold">
                      {selectedEvent.name}
                    </span>
                    <span>{ticket.event_start_formatted}</span>
                    <span className="block">{ticket.venue_name}</span>

                    <div className="flex justify-between my-3 items-center">
                      <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                        <span>Name</span>
                        <span className="font-bold">{user?.full_name}</span>
                      </div>
                      <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                        <span>Tier</span>
                        <span className="font-bold">{ticket.tier.name}</span>
                      </div>
                      <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                        <span>Section</span>
                        <span className="font-bold">{ticket.section.name}</span>
                      </div>

                      <div className="w-1/3 flex flex-col text-xs sm:text-sm">
                        <span>Level</span>
                        <span className="font-bold">
                          {ticket.price_level.name}
                        </span>
                      </div>
                    </div>
                    <div className="mt-5">
                      <QRCode className="w-full" value={`${ticket.uid}`} />
                      <span className="block mt-3 text-sm">{ticket.uid}</span>
                    </div>

                    <div className="flex flex-col items-center justify-center gap-3 px-3 mt-3">
                      <div className="flex justify-between w-full gap-3">
                        <Button
                          onClick={() => setShowEventDescription(true)}
                          variant="pink"
                          className="w-1/2"
                        >
                          Info
                        </Button>

                        <Button
                          className="flex gap-2 w-1/2"
                          onClick={() => setShowTransferModal(ticket.id)}
                          variant="pink"
                        >
                          Send <PaperAirplaneIcon className="h-5" />
                        </Button>
                      </div>

                      {ticket.sponsor_logo_url && (
                        <div className="flex justify-center mt-3">
                          <img
                            src={ticket.sponsor_logo_url}
                            alt="sponsor logo"
                            className="w-16 h-auto"
                          />
                        </div>
                      )}
                    </div>

                    <AddToWalletButton ticketId={ticket.id} />
                  </div>
                );
              })}
            </TicketCarousel>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center">
            <div className="w-full">
              <span className="block py-5 text-2xl">Choose Event</span>
              <SelectInput
                selectedOption={scope}
                options={filterOptions}
                onSelect={(option) => (option ? setScope(option) : null)}
              />
            </div>
            {keys(groupedTickets).length === 0 && (
              <span className="block py-4">
                You currently have no tickets to show.
              </span>
            )}
            {keys(groupedTickets).map((key: string, i: number) => {
              const event = groupedTickets[key][0].event;
              const tickets = groupedTickets[key];

              return (
                <div
                  key={i}
                  className="flex w-full items-center gap-2 py-3 border-b border-gray-300 hover:bg-gray-100"
                  role="button"
                  onClick={() => setSelectedEvent(event)}
                >
                  <div className="w-1/4 sm:w-1/5">
                    <img
                      src={
                        tickets[0].event_image ||
                        "//lvgweb.s3.us-east-2.amazonaws.com/happy/happie-guy-black.svg"
                      }
                      alt={event.name}
                      className="w-full rounded-lg border border-gray-300 p-1"
                    />
                  </div>
                  <div className="w-1/2 sm:w-3/5 flex flex-col">
                    <div className="w-full flex justify-between">
                      <span className="text-sm sm:text-base font-bold">
                        {event.name}
                      </span>
                    </div>
                    <div className="text-xs sm:text-sm w-full flex justify-between items-center">
                      <span>{tickets[0].venue_name}</span>
                    </div>
                    <div className="text-xs sm:text-sm w-full flex justify-between items-center">
                      <span>
                        {format(new Date(event.start_date), "MMM d, yyyy")}
                      </span>
                    </div>
                  </div>
                  <div className="w-1/4 sm:w-1/5 flex flex-col gap-1">
                    <span className="bg-pink/30 text-pink px-3 py-2 text-xs flex gap-1 justify-center items-center hover:text-white hover:bg-pink rounded-full">
                      <span className="hidden">Tickets</span>
                      <span className="sm:hidden">
                        <TicketIcon className="h-4" />
                      </span>
                      {`(${tickets.length})`}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      <TransferModal
        showTransferModal={showTransferModal}
        onRequestClose={() => setShowTransferModal(null)}
      />

      <Modal
        onRequestClose={() => setShowEventDescription(false)}
        isOpen={showEventDescription}
      >
        <button className="absolute top-5 right-5">
          <XMarkIcon
            className="h-6"
            onClick={() => setShowEventDescription(false)}
          />
        </button>
        <div className="mt-6">
          <span>{selectedEvent?.description}</span>
        </div>
      </Modal>
    </>
  );
};

export const TransferModal = ({
  showTransferModal,
  onRequestClose,
}: {
  showTransferModal: number | null;
  onRequestClose: () => void;
}) => {
  const [email, setEmail] = useState<string>("");
  const { showError, showSuccess } = useNotification();
  const { user } = useAuth();
  const [userResults, setUserResults] = useState<User[] | null>(null);
  const handleFindUserClick = () => {
    if (!email) return showError("Please enter an email address");
    makeApiRequest({
      path: "/user_search",
      params: {
        email,
      },
    }).then((res) => {
      if (res.status === 200) {
        const users: User[] = res.data;
        setUserResults(users);
      }
    });
  };

  const sendTransfer = (receiverId: number | string) => {
    if (!user) return showError("You must be logged in to send a transfer");
    if (!showTransferModal) return showError("No ticket selected");
    const ticketId = showTransferModal;

    const payload = {
      email,
      sending_user_id: user?.id,
      receiving_user_id: typeof receiverId === "string" ? null : receiverId,
      ticket_id: ticketId,
    };
    makeApiRequest({
      path: "/ticket_transfers",
      method: "POST",
      params: {
        ticket_transfer: payload,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          showSuccess(
            "Transfer request sent! Your recipient will receive an email with instructions on how to accept the transfer."
          );
          onRequestClose();
        }
      })
      .catch((err) => {
        const errMessage = err.response?.data?.message;
        showError(errMessage);
      });
  };
  return (
    <Modal isOpen={!!showTransferModal} onRequestClose={onRequestClose}>
      <>
        <button className="absolute top-5 right-5">
          <XMarkIcon className="h-6" onClick={onRequestClose} />
        </button>
        <span className="block py-4 text-2xl">Send Ticket</span>
        <span className="block py-4">
          Enter the email address of the person you want to send the ticket to.
        </span>
        <TextInput
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleFindUserClick();
            }
          }}
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          name="email"
          type="email"
          placeholder="Email"
        />
        <Button
          onClick={handleFindUserClick}
          variant="primary"
          className="w-full mt-4"
        >
          Find User
        </Button>
        {userResults && userResults.length > 0 && (
          <div className="mt-4">
            <span className="block py-2 text-2xl">Results</span>
            {userResults?.map((result: User) => {
              return (
                <div className="flex items-center justify-between py-2 text-sm">
                  <span>{result.email}</span>
                  <Button
                    onClick={() => sendTransfer(result.id)}
                    variant="default"
                  >
                    Request Transfer
                  </Button>
                </div>
              );
            })}
          </div>
        )}
        {userResults && userResults.length === 0 && (
          <div className="flex flex-col gap-5 items-center">
            <span className="block py-4">
              No users found with that email address, would you like to invite
              this user and transfer the tickets to them?
            </span>
            <strong>Email: {email}</strong>
            <div className="flex gap-10 justify-center items-center">
              <span role="button" className="text-gray-400 text-sm">
                Cancel
              </span>
              <Button onClick={() => sendTransfer(email)} variant="pink">
                Send Invite
              </Button>
            </div>
          </div>
        )}
      </>
    </Modal>
  );
};
